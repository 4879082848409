import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Page } from "../../ui/templates/page";
import {
    Avatar,
    Backdrop,
    CircularProgress,
    Grid,
    Typography,
    ImageList,
    ImageListItem,
    IconButton, ImageListItemBar, ListSubheader
} from "@mui/material";
import { InfoOutlined } from '@mui/icons-material';

const getBaseUri = () => {
    const host = window.location.host;

    let env: string = 'production';
    if(host?.includes('develop') || host.includes("staging")){
        env = host?.includes('develop') ? 'develop': 'staging';
    }
    switch(env){
        case 'production':
            return 'https://api.bricr.com/v1/get-bricr-data/';
        case 'staging':
            return 'https://api.staging-bricr.com/v1/get-bricr-data/';
        case 'develop':
            return 'https://api.develop-bricr.com/v1/get-bricr-data/';
    }
}

type image =
{
    file: {
        fileName: string,
        permission: "public" | "private",
        id: string,
        key: string,
        url: string,
        fileType: string,
    },
    name: string,
    description?: null | string,
    id: "3ee1b3f6-31e3-4d58-b953-9ada0cfff69c",
    type?: string,
    isMainPicture: boolean;

}
type estateData = {
    street?: string;
    houseNumber?: string;
    city?: string;
    propertyType?: string;
    mainPicture?: image | null;
    pictures?: image[];
    postalCode?: string;

}

export const EstatePage = () => {
    const { address } = useParams<{address?: string}>();
    const to = useNavigate()

    const [data, setData] = useState<estateData>();
    const [valid, setValid] = useState<boolean>(true);

    useEffect(() => {
        if(address){
            fetch(`${getBaseUri()}${address}`
            ).then(function(response) {
                return response.json();
            }).then(function(responseData) {
                const item = JSON.stringify(responseData);
                setData(responseData);
                setValid(!item.includes('404'))
            }).catch(function(e) {
                setValid(false);
                console.log("failed!", e);
            });
        }

    }, [address]);

    if(valid && !data){
        return <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={valid}
            onClick={() => {}}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    }

    if(!valid && !!data){
        to('/');
    }

    return <Page><Grid container spacing={3}>
        <Grid item xs={12}>{data?.mainPicture && <Avatar src={data?.mainPicture?.file?.url} />}<Typography>{`${data?.street} ${data?.houseNumber} ${data?.postalCode} ${data?.city}`}</Typography></Grid>
        {data?.pictures?.length ?
        <Grid item xs={12}>
            <ListSubheader component="div">Pictures</ListSubheader>
            <ImageList variant="masonry" cols={3} gap={8} >
                {data?.pictures.map((item) => (
                    <ImageListItem key={item.name}>
                        <img
                            src={`${item.file.url}?w=248&fit=crop&auto=format`}
                            srcSet={`${item.file.url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                            alt={item.description ?? item.name}
                            loading="lazy"
                        />
                        <ImageListItemBar
                            title={item.description}
                            subtitle={item.name}
                            actionIcon={
                                <IconButton
                                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                    aria-label={`info about ${item.name}`}
                                >
                                    <InfoOutlined />
                                </IconButton>
                            }
                        />
                    </ImageListItem>
                ))}
            </ImageList>
        </Grid> : null}
    </Grid></Page>
}
