import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import { EstatePage } from "../pages/estate-page";
import { Page } from "../../ui/templates/page";

export const AppRoutes = () => {
    return (
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Page>Dashboard 13</Page>} />
            <Route path="/:address" element={<EstatePage />} />
        </Routes>
    </BrowserRouter>)
}
